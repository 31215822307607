import React from "react";
import { HeaderNav } from "./header";
import { HeroSection } from "./HeroSection";
import "./layout.css";
import "../style.css";
import "./utilities.css";
import { Footer } from "./Footer";

const Layout = ({ children, heroSection = true, contactFooter = true }) => {
  return (
    <>
      <HeaderNav />
      {heroSection && <HeroSection />}
      {children}
      <Footer contactFooter={contactFooter} />
    </>
  );
};

export default Layout;
