import React from "react";
export function HeroSection() {
  return (
    <section
      style={{
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        background: `#2f2e41`,
        color: "white",
      }}
    >
      <div className="main-container">
        <div>
          <h1
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="zoomOnHover"
          >
            <span>Simon Sterckx</span>
            <span
              style={{
                fontWeight: 400,
                fontSize: "1.4rem",
              }}
            >
              Software developer based in Örebro, Sweden.
            </span>
          </h1>

          <p
            style={{
              opacity: ".8",
            }}
          >
            <i>Developing high quality web and mobile solutions.</i>
          </p>
        </div>
      </div>
    </section>
  );
}
