import { Link } from "gatsby";
import React from "react";
export function Footer({ contactFooter }: { contactFooter: boolean }) {
  return (
    <footer>
      <div className="main-container">
        {contactFooter && (
          <section
            style={{
              padding: "80px 0",
            }}
          >
            <h3>Let's talk</h3>
            <p>
              Have an interesting project idea that you wish to see come to
              life? Feel free to{" "}
              <Link
                to="/contact"
                className="borderFade"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                {"contact me."}
              </Link>
            </p>
          </section>
        )}
        <p>© {new Date().getFullYear()} Simon Sterckx</p>
      </div>
    </footer>
  );
}
