import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

export const HeaderNav = () => (
  <header
    style={{
      background: `#2f2e41`,
      marginBottom: `0`,
    }}
  >
    <nav
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: "flex",
        justifyContent: "space-between",
        fontSize: "1rem",
      }}
    >
      <Link to="/" className="borderFade">
        sterckx.se
      </Link>

      <div>
        <Link
          to="/developer-story"
          style={{
            marginRight: 12,
          }}
          className="borderFade"
        >
          Developer Story
        </Link>
        <Link to="/contact" className="borderFade">
          Contact
        </Link>
      </div>
    </nav>
  </header>
);
